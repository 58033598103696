import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  // UPDATE,
  // DELETE,
  DELETE_MANY,
} from 'react-admin'

import {
  write,
  writeMultiple,
  getOne,
  getMany,
  getManyByList,
} from './dataMethods'

/* Firebase Data Provider - https://nata.house
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the action type
 * @returns {Promise} the Promise for a response
 */
export default (type, resourceName, params) => {  
  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE:
      return getMany(resourceName, params)
    case GET_MANY:
      return getManyByList(resourceName, params)
    case GET_ONE:
      return getOne(resourceName, params)
    case CREATE:
      return write(type, resourceName, {
        id: params.id,
        data: {
          ...params.data,
          isDeleted: false,
        },
      })
    case DELETE_MANY: {
      const data = params.ids.map(id => ({
        id,
        isDeleted: true,
      }))

      return writeMultiple(resourceName, data)
    }
    default:
      console.error('Undocumented method: ', type)
      return { data: [] }
  }
}
