// Desabilitei pois provavelmente teremos mais funções nesse arquivo
/* eslint-disable import/prefer-default-export */
import { auth } from './firebaseClient'
import getRandomId from '../utils/getRandomId'

export const registerUser = (params) => {
  const { email, password } = params.data

  return auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => {
      auth.sendPasswordResetEmail(email)
      return user.user.uid
    })
    .catch(({ code, message }) => {
      if (code === 'auth/email-already-in-use') {
        return getRandomId()
      }

      throw new Error(message)
    })
}
