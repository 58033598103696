import firebase from 'firebase'
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK } from 'react-admin'

import { db } from './firebaseClient'

const baseConfig = {
  userProfilePath: '/users/',
  userAdminProp: 'isAdmin',
  localStorageTokenName: 'adminFirebaseClientToken',
  handleAuthStateChange: async (auth, config) => {
    if (auth) {
      const authentication = auth.uid ? auth : auth.user

      return db
        .collection('users')
        .doc(authentication.uid)
        .get()
        .then(async (user) => {
          const profile = user.data()

          if (
            profile
            && (profile.category === 'super-admin' || profile.category === 'admin')
          ) {
            const firebaseToken = await authentication.getIdToken()

            localStorage.setItem('user', JSON.stringify(profile))
            localStorage.setItem(config.localStorageTokenName, firebaseToken)

            return { authentication, profile, firebaseToken }
          }

          if (profile && profile.category === 'app-user') {
            firebase.auth().signOut()
            localStorage.removeItem(config.localStorageTokenName)
            throw new Error('Invalid user type!')
          }

          firebase.auth().signOut()
          localStorage.removeItem(config.localStorageTokenName)
          throw new Error('sign_in_error')
        })
    }
    localStorage.removeItem(config.localStorageTokenName)
    localStorage.removeItem('user')
    throw new Error('sign_in_error')
  },
}

export default (config = {}) => {
  const configuration = { ...baseConfig, ...config }

  const firebaseLoaded = () => new Promise((resolve) => {
    firebase.auth().onAuthStateChanged(resolve)
  })

  return async (type, params) => {
    if (type === AUTH_LOGOUT) {
      localStorage.removeItem(configuration.localStorageTokenName)
      localStorage.removeItem('user')
      return firebase.auth().signOut()
    }

    if (firebase.auth().currentUser) {
      await firebase.auth().currentUser.reload()
    }

    if (type === AUTH_CHECK) {
      await firebaseLoaded()

      if (!firebase.auth().currentUser) {
        throw new Error('sign_in_error')
      }

      return true
    }

    if (type === AUTH_LOGIN) {
      const { username, password, alreadySignedIn } = params
      let auth = firebase.auth().currentUser

      if (!auth || !alreadySignedIn) {
        auth = await firebase.auth().signInWithEmailAndPassword(username, password)
      }

      return configuration.handleAuthStateChange(auth, configuration)
    }

    return false
  }
}
