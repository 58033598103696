import * as React from 'react'
import { Admin, Resource } from 'react-admin'
import portugueseMessages from 'ra-language-portuguese'

import MeasurementsList from './resources/measurements'
import { UsersList, UsersCreate } from './resources/users'
// import LoginPage from './components/LoginPage.jsx'

import constants from './utils/constants'
import authProvider from './services/authProvider'
import dataProvider from './services/dataProvider'

const messages = {
  pt: portugueseMessages,
}

const i18nProvider = locale => messages[locale]

const App = () => (
  <Admin
    title="Medidor de Combustível CPE - Administrador"
    dataProvider={dataProvider}
    authProvider={authProvider(constants.authConfig)}
    locale="pt"
    i18nProvider={i18nProvider}
  >
    <Resource
      options={{ label: 'Medições' }}
      name="measurements"
      list={MeasurementsList}
    />
    <Resource
      options={{ label: 'Usuários' }}
      name="users"
      list={UsersList}
      create={UsersCreate}
    />
  </Admin>
)

export default App
