import _ from 'lodash'
import { CREATE } from 'react-admin'

import { db } from './firebaseClient'
import { registerUser } from './helperDataMethods'

export const getOne = (resourceName, params) =>
  db
    .collection(resourceName)
    .doc(params.id)
    .get()
    .then(response => ({ data: response.data() }))
    .catch(error => {
      throw new Error('Failed fetching data', error)
    })

const getMeasurements = async (params) => {
  const { filter, sort } = params

  let user
  if (filter && filter.name) {
    const userQuery = await db.collection('users').where('email', '==', filter.name).get()
    user = userQuery.size ? userQuery.docs[0] : null
  }

  let reference = db.collection('measurements')

  if (sort.field && sort.field !== 'id') {
    reference = reference.orderBy(sort.field, sort.order.toLowerCase())
  }

  if (filter && filter.name && user) {
    reference = reference.where('userId', '==', user.id)
  } else {
    reference = reference.limit(50)
  }

  const docs = (filter && filter.name && !user) ? [] : (await reference.get()).docs

  const data = await Promise.all(docs.map((doc) => {
    const innerData = doc.data()
    return getOne('users', { id: innerData.userId })
      .then(item => item.data)
      .then(userData => ({
        id: doc.id,
        ...innerData,
        datetime: innerData.datetime.toDate(),
        userName: userData.name,
        userEmail: userData.email,
      }))
  }))

  return {
    data,
    total: data.length,
  }
}

export const getMany = async (resourceName, params) => {
  const { sort } = params
  const { page, perPage } = params.pagination

  if (resourceName === 'measurements') {
    return getMeasurements(params)
  }

  const reference = await db
    .collection(resourceName)
    .orderBy(sort.field, sort.order.toLowerCase())

  return reference
    .get()
    .then(response => {
      const startAt = (page - 1) * perPage
      const endAt = page * perPage - 1
      const docs = _.slice(response.docs, startAt, endAt)

      const data = docs.map(doc => {
        const innerData = doc.data()
        const field = resourceName === 'measurements' ? 'datetime' : 'createdAt'

        return {
          ...innerData,
          [field]: innerData[field].toDate(),
        }
      })

      const result = {
        data,
        total: response.docs.length,
      }

      return result
    })
    .catch(error => {
      throw new Error('Failed fetching data', error)
    })
}

export const getManyByList = (resourceName, params) => {
  const requests = []

  params.ids.forEach(id => {
    requests.push(getOne(resourceName, { id }))
  })

  return Promise.all(requests)
    .then(responses => ({
      data: responses.filter(response => response.data).map(value => value.data),
    }))
    .catch(error => {
      throw new Error('Failed fetching data', error)
    })
}

export const write = async (type, resourceName, params) => {
  let { id, data } = params

  if (resourceName === 'users' && type === CREATE) {
    id = await registerUser(params)
    data.id = id
    delete data.password
  }

  const collection = db.collection(resourceName)
  const document = id ? collection.doc(id) : collection.doc()

  if (!id) {
    data.id = document.id
    params.id = document.id
  }

  if (type === CREATE) {
    data.createdAt = new Date()
  }

  return document
    .set(data, { merge: true })
    .then(() => getOne(resourceName, { id: id || data.id }))
    .catch(error => {
      throw new Error('Failed writing data', error)
    })
}

export const writeMultiple = async (resourceName, data) => {
  data.forEach(item => {
    const document = db.collection(resourceName).doc(item.id)

    document.set(item, { merge: true }).catch(error => {
      throw new Error('Failed writing data', error)
    })
  })

  return Promise.resolve({ data: [] })
}
