export default {
  authConfig: {
    userProfilePath: '/users/',
    userCategoryProp: 'category',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyBrTweiNhzAfByTNLKc1q1_2R6iyT6LbTk',
    authDomain: 'cpe-gas.firebaseapp.com',
    databaseURL: 'https://cpe-gas.firebaseio.com',
    projectId: 'cpe-gas',
    storageBucket: 'cpe-gas.appspot.com',
    messagingSenderId: '70339188601',
  },
}
