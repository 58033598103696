import * as React from 'react'
import {
  Create,
  Datagrid,
  List,
  TextField,
  SimpleForm,
  DateField,
  TextInput,
  SelectInput,
} from 'react-admin'

export const UsersList = props => (
  <List {...props} title="Usuários">
    <Datagrid>
      <TextField label="Nome" source="name" />
      <TextField label="Email" source="email" />
      <DateField label="Criado em" source="createdAt" locales="pt-BR" showTime />
    </Datagrid>
  </List>
)

export const UsersCreate = (props) => {
  const { category } = JSON.parse(localStorage.getItem('user'))

  const categoryInputChoices = () => {
    if (category === 'super-admin') {
      return [
        { id: 'super-admin', name: 'Super Admin' },
        { id: 'admin', name: 'Admin' },
        { id: 'app-user', name: 'App User' },
      ]
    }

    if (category === 'admin') {
      return [{ id: 'app-user', name: 'App User' }]
    }

    return []
  }

  return (
    <Create {...props} title="Usuários">
      <SimpleForm>
        <TextInput label="Nome" source="name" />
        <TextInput label="Email" source="email" type="email" />
        <TextInput label="Senha" source="password" type="password" />
        <SelectInput
          label="Categoria"
          source="category"
          choices={categoryInputChoices()}
        />
      </SimpleForm>
    </Create>
  )
}
